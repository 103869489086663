/*Responsive*/
@media (min-width: 1366px) and (max-width: 1919px){
	header {
		padding: 19px 30px 0;
	}
	.slider_dv {
		padding: 215px 30px 0;
	}
	.highlights,
	.showcase,
	.brands{
		padding: 0 15px;
	}
	footer{
		padding: 30px 15px;
	}
	.showcase_inpage{
		padding: 0;
	}
}/*(min-width: 1366px) and (max-width: 1919px*/

@media (min-width: 1200px) and (max-width: 1365px){
	header {
		padding: 19px 30px 0;
	}
	.slider_dv {
		padding: 215px 30px 0;
		.form {
			padding: 35px 69px 70px;
			width: 550px;
		}
		.right {
			float: left;
			width: calc(100% - 550px);
		}
	}/*slider_dv*/
	.highlights,
	.showcase,
	.brands{
		padding: 0 15px;
	}
	.highlights{
		.highlights_slc {
			.item {
				padding: 30px 15px;
				.items {
					.sm_item {
						+ .sm_item {
							margin-left: 3px;
						}
					}/*sm_item*/
				}/*items*/
			}/*item*/
		}/*highlights_slc*/
	}/*highlights*/
	.showcase{
		.item {
			.items{
				.sm_item {
					margin-bottom: 11px;
					margin-right: 2.5%;
					margin-left: 2.5%;
				}/*sm_item*/
			}/*items*/
		}/*item*/
	}/*showcase*/
	footer{
		padding: 30px 15px;
		.social a + a {
			margin-left: 7px;
		}
	}/*footer*/
	.band {
		padding: 15px;
	}
	.showcase_inpage{
		padding: 0;
	}
}/*(min-width: 1200px) and (max-width: 1365px)*/
 
@media (min-width: 992px) and (max-width: 1199px) {
	header {
		padding: 19px 30px 0;
		.menu {
			.droopmenu > li > a,
			.droopmenu > li > span {
				padding: 0 5px;
				font-size: 15px;
			}
			.tel {
				font-size: 15px;
			}/*tel*/
		}/*menu*/
	}/*header*/
	.slider_dv {
		padding: 180px 30px 0;
		height: 775px;
		.form {
			padding: 20px 15px 30px;
			width: 440px;
		}
		.right {
			float: left;
			width: calc(100% - 440px);
			.slider-byk{
				.item{
					.top {
						.title {
							padding-left: 30px;
							width: calc(100% - 190px);
							.mrk,.mdl{
								font-size:40px;
							}
						}/*title*/
						.price{
							a {
								span{
									&:first-child {
										padding: 10px 0;
									}
									&:last-child {
										padding: 10px 0;
									}
								}/*span*/
							}/*a*/
						}/*price*/
					}/*top*/
				}/*item*/
			}/*slider-byk*/
		}/*right*/
	}/*slider_dv*/
	.highlights,
	.showcase,
	.brands{
		padding: 0 15px;
	}
	.highlights{
		.highlights_slc {
			.item {
				padding: 30px 15px;
				.items {
					.sm_item {
						+ .sm_item {
							margin-left: 0;
						}
						margin-right: 10px;
						margin-bottom: 10px;
					}/*sm_item*/
				}/*items*/
			}/*item*/
		}/*highlights_slc*/
	}/*highlights*/
	.showcase{
		.item {
			height: 530px;
			&:before {
				bottom: 102px;
				height: 215px;
			}
			.items{
				.sm_item {
					margin-bottom: 8px;
					margin-right: 1%;
					margin-left: 1%;
					width: 55px;
				}/*sm_item*/
			}/*items*/
			a {
				padding: 11px;
			}
		}/*item*/
	}/*showcase*/
	.brands{
		.brands_slc{
			.slick-slide {
				margin: 0 5px;
			}
			.slick-list {
				margin: 0 -5px;
			}
		}/*brands_slc*/
	}/*brands*/
	footer{
		padding: 30px 15px;
		.social {
			a{
				+ a {
					margin-left: 4px;
				}
				height: 35px;
				width: 35px;
				line-height: 33px;
				font-size: 18px;
			}/*a*/
		}/*social*/
	}/*footer*/
	.band {
		padding: 15px;
	}
	.showcase_inpage{
		padding: 0;
	}
}/*(min-width: 992px) and (max-width: 1199px*/

@media (min-width: 768px) and (max-width: 991px) {
	header {
		padding: 10px 15px 0;
		.menu {
			margin-top: 30px;
			.droopmenu > li > a,
			.droopmenu > li > span {
				padding: 0 5px;
				font-size: 15px;
			}
			.tel {
				font-size: 15px;
				position: absolute;
				right: 15px;
				top:10px;
			}/*tel*/
		}/*menu*/
	}/*header*/
	.slider_dv {
		padding: 140px 15px 0;
		height: 620px;
		.form {
			padding: 20px 15px 30px;
			width: 350px;
			.title {
				font-size: 20px;
				margin-bottom: 30px;
				strong {
					font-size: 24px;
				}
			}/*title*/
			.cst_input{
				input {
					padding-left: 10px;
					font-size: 14px;
					padding-right: 30px;
				}
				&:before {
					right: 10px;
					font-size: 14px;
				}/*before*/
			}/*cst_input*/
			.select2-container .select2-selection--single .select2-selection__rendered {
				padding: 11px 30px 11px 10px;
			}
			.select2-container--default .select2-selection--single .select2-selection__arrow {
				right: 10px;
			}
		}/*form*/
		.right {
			float: left;
			width: calc(100% - 350px);
			.slider-byk{
				.item{
					.top {
						.title {
							padding-left: 20px;
							width: calc(100% - 150px);
							.mrk,.mdl{
								font-size:30px;
							}
						}/*title*/
						.price{
							width: 150px;
							a {
								border: 2px solid #ed6d05;
								span{
									&:first-child,
									&:last-child{
										font-size:15px;
										padding: 5px 0;
									}
								}/*span*/
							}/*a*/
						}/*price*/
					}/*top*/
					.thumbs {
						.item {
							width: 90px;
							margin-left: 10px;
						}
					}/*thumbs*/
				}/*item*/
			}/*slider-byk*/
		}/*right*/
	}/*slider_dv*/
	.highlights,
	.showcase,
	.brands{
		padding: 0;
	}
	.highlights{
		.highlights_slc {
			.item {
				padding: 30px 15px;
				.items {
					.sm_item {
						+ .sm_item {
							margin-left: 0;
						}
						margin-right: 5px;
						margin-bottom: 5px;
						width: 60px;
					}/*sm_item*/
				}/*items*/
			}/*item*/
		}/*highlights_slc*/
	}/*highlights*/
	.showcase{
		.item {
			height: 530px;
			&:before {
				bottom: 102px;
				height: 215px;
			}
			.items{
				.sm_item {
					margin-bottom: 8px;
					margin-right: 1%;
					margin-left: 1%;
					width: 55px;
				}/*sm_item*/
			}/*items*/
			a {
				padding: 11px;
			}
		}/*item*/
	}/*showcase*/
	.news {
		.news_slc {
			padding: 0;
			.slick-prev {
				left: -17px;
			}
			.slick-next {
				right: -17px;
			}
		}/*news_slc*/
	}/*news*/
	.brands{
		.brands_slc{
			.slick-slide {
				margin: 0 5px;
			}
			.slick-list {
				margin: 0 -5px;
			}
		}/*brands_slc*/
	}/*brands*/
	footer{
		padding: 30px 0;
		.social {
			a{
				+ a {
					margin-left: 0
				}
				height: 35px;
				width: 35px;
				line-height: 33px;
				font-size: 18px;
				margin: 0 5px 5px 0;
			}/*a*/
		}/*social*/
	}/*footer*/
	.band {
		padding: 15px 0;
	}
	.showcase_inpage{
		padding: 0;
	}
	.news_list .item .text {
		padding: 50px 10px 30px 0;
	}
	.price_detail {
		.form {
			.title {
				font-size: 23px;
				margin-bottom: 20px;
			}/*title*/
			.cst_input{
				input {
					padding-left: 7px;
					font-size: 14px;
					padding-right: 25px;
				}
				&:before {
					right: 7px;
					font-size: 14px;
				}/*before*/
			}/*cst_input*/
			.select2-container .select2-selection--single .select2-selection__rendered {
				padding: 11px 25px 11px 7px;
			}
			.select2-container--default .select2-selection--single .select2-selection__arrow {
				right: 7px;
			}
			.select2-container--default .select2-selection--single .select2-selection__placeholder {
				font-size:14px;
			}
		}/*form*/
	}/*price_detail*/

}/*(min-width: 768px) and (max-width: 991px)*/

@media (min-width: 576px) and (max-width: 767px) {
	header {
		padding: 10px 15px 0;
		.menu {
			margin-top: 30px;
			.tel {

			}/*tel*/
			.droopmenu-mclose span::after {
				top: 13px;
				left: 0;
			}
			.droopmenu-toggle i::after,
			.droopmenu-toggle i::before {
				background:#fff;
			}
			.droopmenu > li > a,
			.droopmenu > li > span {
				color: $color1;
				height: 40px;
				line-height: 40px;
			}
			.droopmenu-toggle {
				right: 0;
				top: 0;
				background-color:$color2 !important;
				&:before{
					content: attr(data-title);
					color: #fff;
					font-weight: 300;
					font-size: 17px;
					position: absolute;
					left: -65px;
					top: 1px;
				}/*before*/
			}/*droopmenu-toggle*/
		}/*menu*/
	}/*header*/
	.slider_dv {
		padding: 140px 15px 130px;
		height: auto;
		background-size: cover;
		display: inline-block;
		width: 100%;
		margin-bottom: -10px;
		.form {
			padding: 20px 15px 30px;
			width: 100%;
			margin-bottom: 30px;
			.title {
				font-size: 20px;
				margin-bottom: 30px;
				strong {
					font-size: 24px;
				}
			}/*title*/
			.cst_input{
				input {
					padding-left: 10px;
					font-size: 14px;
					padding-right: 30px;
				}
				&:before {
					right: 10px;
					font-size: 14px;
				}/*before*/
			}/*cst_input*/
			.select2-container .select2-selection--single .select2-selection__rendered {
				padding: 11px 30px 11px 10px;
			}
			.select2-container--default .select2-selection--single .select2-selection__arrow {
				right: 10px;
			}
		}/*form*/
		.right {
			float: left;
			width: 100%;
			.slider-byk{
				.item{
					.top {
						.title {
							padding-left: 20px;
							width: calc(100% - 150px);
							.mrk,.mdl{
								font-size:30px;
							}
						}/*title*/
						.price{
							width: 150px;
							a {
								border: 2px solid #ed6d05;
								span{
									&:first-child{
										font-size:15px;
										padding: 5px 0;
										background-color: #fff;
									}
									&:last-child{
										font-size:15px;
										padding: 5px 0;
									}
								}/*span*/
							}/*a*/
						}/*price*/
					}/*top*/
					.thumbs {
						.item {
							width: 90px;
							margin-left: 10px;
						}
					}/*thumbs*/
				}/*item*/
			}/*slider-byk*/
		}/*right*/
	}/*slider_dv*/
	.highlights,
	.showcase,
	.brands{
		padding: 0;
	}
	.highlights{
		.highlights_slc {
			.item {
				padding: 30px 15px;
				.items {
					.sm_item {
						+ .sm_item {
							margin-left: 0;
						}
						margin-right: 5px;
						margin-bottom: 5px;
						width: 60px;
					}/*sm_item*/
				}/*items*/
			}/*item*/
		}/*highlights_slc*/
	}/*highlights*/
	.showcase{
		.item {
			height: 530px;
			&:before {
				bottom: 80px;
				height: 250px;
			}
			.imaj {
				margin-top: 45px;
			}
			.items{
				.sm_item {
					margin-bottom: 8px;
					margin-right: 1%;
					margin-left: 1%;
					width: 55px;
				}/*sm_item*/
			}/*items*/
			a {
				padding: 11px;
			}
		}/*item*/
	}/*showcase*/
	.newsletter {
		.bltn {
			width: 100%;
		}/*bltn*/
	}/*newsletter*/
	.news {
		.news_slc {
			padding: 0;
			.item{
				.desc {
					font-size: 14px;
					line-height: 18px;
				}
				.button {
					padding: 6px 30px;
					font-size: 14px;
					margin: 20px auto 0;
				}
			}/*item*/
			.slick-prev {
				left: -17px;
			}
			.slick-next {
				right: -17px;
			}
		}/*news_slc*/
	}/*news*/
	.brands{
		.brands_slc{
			.slick-slide {
				margin: 0 5px;
			}
			.slick-list {
				margin: 0 -5px;
			}
		}/*brands_slc*/
	}/*brands*/
	footer{
		padding: 10px 0 30px;
		margin-top: 50px;
		.f_title {
			margin-bottom: 7px;
			margin-top: 30px;
		}
		.social {
			a{
				+ a {
					margin-left: 0
				}
				height: 32px;
				width: 32px;
				line-height: 30px;
				font-size: 17px;
				margin: 0 4px 4px 0;
			}/*a*/
		}/*social*/
	}/*footer*/
	.band {
		padding: 15px 0;
	}
	.showcase_inpage{
		padding: 0;
	}
	.cart_top button {
		padding: 10px;
		height: 65px;
	}
	.price_detail {
		.nav li a {
			padding: 10px 0;
			font-size: 14px;
		}
		.form {
			.title {
				font-size: 23px;
				margin-bottom: 20px;
			}/*title*/
			.cst_input{
				input {
					padding-left: 7px;
					font-size: 14px;
					padding-right: 25px;
				}
				&:before {
					right: 7px;
					font-size: 14px;
				}/*before*/
			}/*cst_input*/
			.select2-container .select2-selection--single .select2-selection__rendered {
				padding: 11px 25px 11px 7px;
			}
			.select2-container--default .select2-selection--single .select2-selection__arrow {
				right: 7px;
			}
			.select2-container--default .select2-selection--single .select2-selection__placeholder {
				font-size:14px;
			}
		}/*form*/
	}/*price_detail*/
	.start {
		h1{
			font-size: 24px;
			&.back_title {
				padding-right: 0;
			}
			.back {
				position: relative;
				display: block;
				bottom: 0;
				text-align: center;
			}
		}/*h1*/
	}/*start*/
}/*(min-width: 576px) and (max-width: 767px)*/

@media (max-width: 575px) {
	header {
		padding: 10px 15px 0;
		.menu {
			margin-top: 30px;
			.tel {
				position: absolute;
				right: 15px;
				top: 23px;
				font-size: 14px;
				background: url("../images/phone_icon.png") no-repeat center left/28px;
			}/*tel*/
			.droopmenu-navbar {
				margin-top: 20px;
			}
			.droopmenu-mclose span::after {
				top: 13px;
				left: 0;
			}
			.droopmenu-toggle i::after,
			.droopmenu-toggle i::before {
				background:#fff;
			}
			.droopmenu > li > a,
			.droopmenu > li > span {
				color: $color1;
				height: 40px;
				line-height: 40px;
			}
			.droopmenu-toggle {
				right: 0;
				top: 0;
				background-color:$color2 !important;
				&:before{
					content: attr(data-title);
					color: #fff;
					font-weight: 300;
					font-size: 17px;
					position: absolute;
					left: -65px;
					top: 1px;
				}/*before*/
			}/*droopmenu-toggle*/
		}/*menu*/
	}/*header*/
	.slider_dv {
		padding: 140px 15px 100px;
		height: auto;
		background-size: cover;
		display: inline-block;
		width: 100%;
		margin-bottom: -10px;
		.form {
			padding: 20px 15px 30px;
			width: 100%;
			margin-bottom: 30px;
			.show_btn {
				background-color:#fff;
				width: 30px;
				height: 30px;
				border-radius: 0 5px 0 5;
				text-align: center;
				line-height: 26px;
				right: 0;
				top:0;
			}
			.title {
				font-size: 19px;
				margin-bottom: 30px;
				strong {
					font-size: 23px;
				}
			}/*title*/
			.cst_input{
				input {
					padding-left: 7px;
					font-size: 13px;
					padding-right: 25px;
				}
				&:before {
					right: 7px;
					font-size: 14px;
				}/*before*/
			}/*cst_input*/
			.select2-container .select2-selection--single .select2-selection__rendered {
				padding: 11px 25px 11px 7px;
			}
			.select2-container--default .select2-selection--single .select2-selection__arrow {
				right: 7px;
			}
			.select2-container--default .select2-selection--single .select2-selection__placeholder {
				font-size:13px;
			}
		}/*form*/
		.right {
			float: left;
			width: 100%;
			.slider-byk{
				.item{
					.top {
						.title {
							padding-left:0;
							width: calc(100% - 130px);
							.mrk,
							.mdl{
								font-size:26px;
							}
							.egk {
								font-size: 25px;
							}/*egk*/
						}/*title*/
						.price{
							width: 130px;
							a {
								border: 2px solid #ed6d05;
								&:before {
									bottom: -15px;
									border-width: 16px 20px 0 20px;
								}
								span{
									&:first-child{
										font-size:14px;
										padding: 5px 0;
										background-color: #fff;
									}
									&:last-child{
										font-size:14px;
										padding: 5px 0;
									}
								}/*span*/
							}/*a*/
						}/*price*/
					}/*top*/
					.thumbs {
						.item {
							width: 80px;
							height: 40px;
							margin-left: 5px;
						}/*item*/
					}/*thumbs*/
				}/*item*/
			}/*slider-byk*/
		}/*right*/
	}/*slider_dv*/
	.highlights,
	.showcase,
	.brands{
		padding: 0;
	}
	.highlights{
		.highlights_slc {
			.item {
				padding: 30px 15px;
				.items {
					.sm_item {
						+ .sm_item {
							margin-left: 0;
						}
						margin-right: 5px;
						margin-bottom: 5px;
						width: 60px;
					}/*sm_item*/
				}/*items*/
			}/*item*/
		}/*highlights_slc*/
	}/*highlights*/
	.showcase{
		.item {
			height: 530px;
			width: 290px;
			margin: 0 auto 15px;
			&:before {
				bottom: 80px;
				height: 250px;
			}
			.imaj {
				margin-top: 45px;
			}
			.items{
				.sm_item {
					margin-bottom: 8px;
					margin-right: 1%;
					margin-left: 1%;
					width: 55px;
				}/*sm_item*/
			}/*items*/
			a {
				padding: 11px;
			}
		}/*item*/
	}/*showcase*/
	.newsletter {
		h2 {
			font-size: 24px;
			padding: 60px 0 10px;
		}
		.bltn {
			width: 100%;
		}/*bltn*/
	}/*newsletter*/
	.news {
		.news_slc {
			padding: 0;
			.item{
				.desc {
					font-size: 14px;
					line-height: 18px;
				}
				.button {
					padding: 6px 30px;
					font-size: 14px;
					margin: 20px auto 0;
				}
			}/*item*/
			.slick-prev {
				left: -17px;
			}
			.slick-next {
				right: -17px;
			}
		}/*news_slc*/
	}/*news*/
	.brands{
		.brands_slc{
			.slick-slide {
				margin: 0 5px;
			}
			.slick-list {
				margin: 0 -5px;
			}
		}/*brands_slc*/
	}/*brands*/
	footer{
		padding: 10px 0 30px;
		margin-top: 50px;
		.f_title {
			margin-bottom: 7px;
			margin-top: 30px;
		}
		.social {
			text-align: center;
			a{
				+ a {
					margin-left: 0
				}
				height: 32px;
				width: 32px;
				line-height: 30px;
				font-size: 17px;
				margin: 0 4px 4px 0;
			}/*a*/
		}/*social*/
	}/*footer*/
	.band {
		padding: 15px 0 5px;
		text-align: center;
		.iwt {
			float: none;
			margin: 10px auto;
			display: table;
		}
	}/*band*/
	.showcase_inpage{
		padding: 0 15px;
	}
	.cart_top button {
		padding: 10px 3px;
		font-size:12px;
		height: 65px;
		line-height: 1.2;
	}
	.price_detail {
		.price-byk .item {
			height: 210px;
		}
		.nav li a {
			padding: 10px 5;
			font-size: 14px;
			height: 65px;
			line-height: 1.2;
		}
		.form {
			.title {
				font-size: 23px;
				margin-bottom: 20px;
			}/*title*/
			.cst_input{
				input {
					padding-left: 7px;
					font-size: 13px;
					padding-right: 25px;
				}
				&:before {
					right: 7px;
					font-size: 14px;
				}/*before*/
			}/*cst_input*/
			.select2-container .select2-selection--single .select2-selection__rendered {
				padding: 11px 25px 11px 7px;
			}
			.select2-container--default .select2-selection--single .select2-selection__arrow {
				right: 7px;
			}
			.select2-container--default .select2-selection--single .select2-selection__placeholder {
				font-size:13px;
			}
		}/*form*/
	}/*price_detail*/
	.start {
		h1{
			font-size: 24px;
			&.back_title {
				padding-right: 0;
			}
			.back {
				position: relative;
				display: block;
				bottom: 0;
				text-align: center;
			}
		}/*h1*/
	}/*start*/
}/*(max-width: 575px)*/