header{
	position: absolute;
	z-index: 9;
	left: 0;
	right: 0;
	top:0;
	padding: 19px 300px 0;
	.logo{
		float:left;
		width: 111px;
		padding-top: 10px;
		h1{
			display: block;
			color: #fff;
			font-weight: 600;
			font-size: 20px;
			white-space: nowrap;
		}
	}/*logo*/
	.menu{
		float:left;
		width: calc(100% - 111px);
		text-align: right;
		.droopmenu-navbar {
			display: inline-block;
			vertical-align: middle;
		}
		.dm-nav-brand a,
		.droopmenu-brand,
		.droopmenu > li > a,
		.droopmenu > li > span {
			line-height: 70px;
			height: 70px;
			font-size: 16px;
			color: #fff;
			font-weight: 700;
		}
		.droopmenu > li > a,
		.droopmenu > li > span {
			padding: 0 21px;
			&:hover{
				color:$color2;
			}
		}
		.droopmenu {
			float: right;
		}
		.tel{
			color:$color2;
			font-size: 16px;
			font-weight: 700;
			display: inline-block;
			vertical-align: middle;
			background: url("../images/phone_icon.png") no-repeat center left;
			padding: 4px 0 15px 22px;
			margin-left: 10px;
		}
	}/*menu*/
}/*header*/