@import 'import/reset.scss';
@import 'import/header.scss'; 
@import 'import/home_page.scss';

.in_body{
	header{
		box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);
		background: url("../images/slider_bg.jpg") no-repeat center top/cover;
		padding-bottom: 10px;
	}
}/*in_body*/

.start{
	padding: 150px 0 20px;
	position: relative;
	h1{
		font-size:30px;
		font-weight: 700;
		color:#222;
		border-bottom: 1px solid #ddd;
		padding-bottom: 30px;
		margin-bottom: 30px;
		text-transform: uppercase;
		padding-right: 0;
		position: relative;
		&.back_title{
			padding-right: 240px;
		}
		.back{
			position: absolute;
			right: 0;
			bottom: 32px;
			font-size: 14px;
			font-weight: 700;
			color:#222;
			border: 1px solid $color1;
			padding: 8px 10px;
			display: inline-block;
			border-radius: 50px;
			&:hover{
				background-color: $color1;
				color:#fff;
			}
		}
	}/*h1*/
}/*start*/

.page_text{
	dl, ol, ul {
		margin-left:15px;
	}
	p{}
}/*page_text*/

.showcase_inpage{
	padding: 0;
	.item {
		.items {
			.sm_item {
				padding-top: 8px;
				margin-bottom: 12px;
				margin-right: 2.75%;
				margin-left: 2.75%;
			}/*sm_item*/
		}/*items*/
	}/*item*/


}/*showcase_inpage*/

.contact_page{
	.contact-form {
		input {
			height: 50px;
		}
		input,
		textarea {
			display: block;
			width: 100%;
			height: 50px;
			padding: 0 40px;
			font-size: 16px;
			line-height: 1.42857;
			color: #000;
			background-color: #fff;
			border: 1px solid #ddd;
			border-radius: 8px;
			transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
			&:focus{
				border-color: $color2;
				box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
			}
			&::placeholder{
				opacity: 1;
				color: #000;
				font-size:14px;
			}
		}
		textarea {
			padding-top: 15px;
			height:200px;
		}
		.input-box,
		.textarea-box {
			position: relative;
			margin: 0 0 15px;
		}
		button{
			padding: 13px 0;
			width: 100%;
			color: #fff;
			font-size: 16px;
			font-weight: 900;
			text-align: center;
			background-color: $color2;
			transition: .5s ease-in-out;
			border: 0;
			border-radius: 5px;
			margin-top: 30px;
			&:hover{
				background-color: $color1;
			}
		}
		.contact-icon {
			position: absolute;
			left: 15px;
			top: 14px;
			i {
				color: $color1;
				font-size: 16px;
			}/*i*/
		}/*contact-icon */
	}/*contact-form*/
	.contact-info {
		padding: 15px;
		margin-bottom: 30px;
		background-color: #fff;
		border-radius: 8px;
		border: 1px solid #ddd;
		.in_title {
			margin-left: 15px;
			margin-bottom: 20px;
			font-size: 20px;
			display: inline-block;
			color:#2a2a2a;
			font-weight: 700;
		}
		.info-icon i {
			background-color: $color1;
			font-size: 18px;
			display: inline-block;
			height: 45px;
			width: 45px;
			text-align: center;
			line-height: 45px;
			border-radius: 5px;
			color: #fff;
		}
		.info-item {
			margin: 0 0 20px
		}
		.info-content {
			padding-left: 20px;
			p {
				margin: 0;
				font-size: 14px;
				color:#2a2a2a;
				a{
					color:#2a2a2a;
				}
			}/*p*/
			h5{
				color:#2a2a2a;
				font-weight:bold;
				margin-bottom:3px;
				font-size: 18px;
				line-height: 18px;
			}
		}/*info-content*/
	}/*contact-info*/
}/*contact_page*/

.cart_top{
	position: relative;
	display: table;
	width: 100%;
	margin-bottom: 30px;
	button{
		background-color: $color1;
		color: #fff;
		float: left;
		border: 0 none;
		font-size: 14px;
		font-weight: 300;
		margin-right: 2%;
		width: 32%;
		padding: 10px 0;
		border-radius: 7px;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			left: -70px;
			background-color: $color1;
			height: 10px;
			top: 50%;
			transform: translate(0,-50%);
			width: 70px;
			z-index: -4;
		}/*before*/
		&:first-child{
			&:before {
				display:none;
			}
		}/*first-child*/
		&:last-child{
			margin: 0;
			&:after {
				display:none;
			}
		}/*last-child*/
		&.active{
			background-color: $color2;
			color:#fff;
			&:after {
				content: "";
				position: absolute;
				right: -70px;
				background-color: $color2;
				height: 10px;
				top: 50%;
				transform: translate(0,-50%);
				width: 70px;
				z-index: -2;
			}/*after*/
		}/*active*/
	}/*button*/
}/*cart_top*/

.price_detail{
	.price-byk{
		padding: 15px;
		background-color: #fff;
		border-radius: 8px;
		margin-bottom: 30px;
		.item{
			position: relative;
			overflow: hidden;
			height: 300px;
			img{
				position: absolute;
				left: 50%;
				top:50%;
				transform: translate(-50%,-50%);
				max-width: 95%;
				max-height: 95%;
			}
		}/*item*/
	}/*price-byk*/
	.price-kck{
		padding: 5px 10px;
		background-color: #fff;
		border-radius: 8px;
		.item{
			position: relative;
			overflow: hidden;
			height: 60px;
			cursor: pointer;
			img{
				position: absolute;
				left: 50%;
				top:50%;
				transform: translate(-50%,-50%);
				max-width: 100%;
				max-height: 100%;
			}
		}/*item*/
		.slick-slide {
			margin: 0 15px;
		}
		.slick-list {
			margin: 0 -15px;
		}
	}/*price-kck*/
	.feature_item{
		margin-top: 30px;
		.sm_item{
			background-color: #fff;
			border-radius: 3px;
			padding: 0 10px;
			text-align: center;
			margin-bottom: 15px;
			border: 1px solid #ddd;
			span{
				display: block;
				font-weight: 300;
				color:#222;
				font-size:14px;
				line-height: 1;
				border-bottom: 1px solid #ddd;
				padding: 8px 0;
			}
			strong{
				color:$color1;
				font-weight: 700;
				font-size:14px;
				display: block;
				padding: 8px 0;
			}
		}/*sm_item*/
	}/*items*/
	.form{
		background-color: #fff;
		position: relative;
		border-radius: 8px;
		padding: 30px 15px;
		float:left;
		width: 100%;
		margin-bottom: 30px;
		.title{
			color:$color1;
			font-weight: 700;
			font-size:22px;
			margin-bottom: 20px;
		}/*title*/

		.select2-container .select2-selection--single {
			height: 50px;
		}
		.select2-container--default .select2-selection--single {
			background-color: #fff;
			border: 1px solid #ddd;
			border-radius: 5px;
		}
		.select2-container .select2-selection--single .select2-selection__rendered {
			padding: 11px 50px 11px 15px;
		}
		.select2-container--default .select2-selection--single .select2-selection__arrow b {
			display: none;
		}
		.select2-container--default .select2-selection--single .select2-selection__arrow {
			height: auto;
			position: absolute;
			top: 50%;
			right: 20px;
			width: auto;
			transform: translateY(-50%);
			&:before{
				content: "\f107 ";
				color: $color1;
				font-family: fontawesome;
				position: absolute;
				top:50%;
				transform: translateY(-50%);
				right: 0;
				font-size:22px;
			}
		}

		.cst_input{
			position: relative;
			height: 50px;
			margin: 30px 0;
			&:before{
				content: "\f073";
				color: $color1;
				font-family: fontawesome;
				position: absolute;
				top:50%;
				transform: translateY(-50%);
				right: 20px;
				font-size:16px;
			}
			input{
				width: 100%;
				background-color: #fff;
				border-radius: 5px;
				border: 1px solid #ddd;
				height: 100%;
				padding-left: 18px;
				font-size: 16px;
				color: #222;
				font-weight: 300;
				padding-right: 50px;
			}/*input*/
		}/*cst_input*/

		.button{
			padding: 13px 0;
			width: 100%;
			color:#fff;
			font-size:16px;
			font-weight: 900;
			text-align: center;
			background-color: $color2;
			transition: .5s ease-in-out;
			border: 0;
			border-radius: 5px;
			&:hover{
				background-color: $color1;
			}
		}
		input{
			&::placeholder{
				opacity: 1!important;
				color:#222!important;
			}
		}

		.lbn{
			tr td:last-child {
				text-align: right;
				font-weight: bold;
				color: $color1;
			}
		}/*lbn*/

		.ek_fe{
			tr td:nth-child(2) {
				color:$color2;
				font-weight: bold;
			}
			a {
				padding: 3px 5px;
				border-radius: 3px;
				background-color: $color1;
				color: #fff;
				font-weight: bold;
				font-size: 14px;
				display: block;
				text-align: center;
				&.active{
					background-color: $color2;
				}
			}/*a*/
		}/*ek_fe*/
	}/*form*/
	.alert {
		font-size: 14px;
		padding: 10px;
	}
	.m_title{
		font-weight: 600;
		color: $color1;
		font-size:14px;
		display: block;
		width: 100%;
		margin-bottom: 5px;
	}
	.info_box{
		p{
			font-size:14px;
		}
		.table{
			text-align: center;
			color:#222;
			background-color: #fff;
			border-radius: 8px;
			overflow: hidden;
			margin-bottom: 0;
			td, th {
				padding: 5px;
				font-size: 14px;
			}
		}/*table*/
	}/*info_box*/
	.bottom_text{
		text-align: center;
		strong{
			display: block;
			font-size:18px;
			color:$color2;
		}
		p{
			display: block;
			font-size:14px;
		}
	}/*bottom_text*/
	.nav {
		border: 0;
		li {
			position: relative;
			text-align: center;
			border: 0;
			a {
				box-shadow: 0 0 20px rgba(0,0,0,0.1);
				border-radius: 8px;
				padding: 10px;
				color:#222;
				border: 0;
				background-color: #fff;
				&.active,
				&:hover,
				&:focus{
					color: #fff;
					background-color: $color1;
					border: 0;
				}
			}/*a*/
		}/*li*/
	}/*nav*/
	.tab-pane.active {
		animation: slide-down .5s ease-out;
	}
	@keyframes slide-down {
		0% { opacity: 0; transform: translateY(100%); }
		100% { opacity: 1; transform: translateY(0); }
	}
	.tab-content {
		margin-top: 30px;
		border: 1px solid #ddd;
		background-color: #fff;
		border-radius: 8px;
		padding:15px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		display: inline-block;
		width: 100%;
	}
	&.step3{
		.form_title {
			font-weight: 700;
			display: block;
			width: 100%;
			margin-bottom: 15px;
			font-size: 18px;
			color:$color2;
		}
		.table td,
		.table th {
			padding: 5px 10px;
			font-size: 14px;
			a{
				color:$color1;
			}
		}
		.contact_page{
			.contact-form.credit-card {
				.input-box{
					label{
						font-size: 14px;
						display: block;
						font-weight: 700;
						margin-bottom: 2px;
					}
					input{
						padding: 0 15px;
						&::placeholder{
							opacity: .5;
						}
					}/*input*/
				}/*input-box*/
			}/*contact-form*/
		}/*contact_page*/
	}/*step3*/
}/*price_detail*/


.news_list{
	.item{
		border: 1px solid #ddd;
		position: relative;
		margin-bottom: 30px;
		.imaj{
			img{
				width: 100%;
				height: 320px;
				object-fit: cover;
			}
		}/*imaj*/
		.text{
			padding: 50px 20px 30px;
			position: relative;
			min-height: 320px;
			.date-time {
				position: absolute;
				top: -1px;
				right: -1px;
				padding: 10px;
				border: 1px solid #ddd;
				max-width: 255px;
				span {
					font-size:14px;
					color:#000;
					letter-spacing: -1px;
				}
				i {
					display: block;
					float: right;
					margin-left: 10px;
				}
			}
			h2 {
				font-size: 26px;
				color: #5f717f;
				margin: 0 0 25px 0;
				letter-spacing: -1px;
				height: 40px;
			}
			p {
				font-size:14px;
				color:#000;
				margin: 0;
				letter-spacing: -1px;
				height: 125px;
			}
			a {
				padding: 10px 15px;
				position: absolute;
				bottom: 40px;
				color: #000;
				border: 1px solid $color2;
				display: inline-block;
				border-radius: 50px;
				font-size: 14px;
				transition: .5s;
				&:hover{
					color:#fff;
					background-color: $color2;
				}
			}/*a*/
		}/*text*/
	}/*item*/
	.show-more {
		border-bottom: 1px solid #ddd;
		height: 20px;
		text-align: center;
		margin: 60px 0 50px;
		width: 100%;
		display: block;
		.btn {
			min-width: 220px;
			height: 40px;
			padding: 0;
			position: relative;
			background: #fff;
			border:1px solid #ccc;
			color: #000;
			display: inline-block;
			border-radius: 50px;
			&:after,
			&:before {
				content: '';
				position: absolute;
				top: 18px;
				height: 1px;
				width: 60px;
				background: #f4f4f4;
			}
			&:before {
				left: -61px;
			}
			&:after {
				right: -61px;
			}
		}/*btn*/
	}
}/*news_list*/



@import 'import/footer.scss';
@import 'import/responsive.scss';