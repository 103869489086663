footer{
	background-color: #fff;
	margin-top: 100px;
	padding: 90px 300px 20px;

	.f_title{
		display: block;
		font-size:16px;
		font-weight: 700;
		color:#222;
		margin-bottom: 38px;
	}
	ul{
		li{
			list-style: none;
			display: block;
			margin-bottom: 6px;
			a{
				display: block;
				font-size:16px;
				font-weight: 300;
				color:#222;
			}
		}/*li*/
	}/*ul*/
	address{
		p{
			display: block;
			font-size:16px;
			font-weight: 300;
			color:#222;
			margin-bottom: 6px;
			a{
				color:#222;
				&.colorlink{
					color:$color2;
					font-weight: 700;
				}
			}/*a*/
		}/*p*/
	}/*address*/
	.social{
		text-align: right;
		a{
			color:$color1;
			border: 1px solid $color1;
			border-radius: 50%;
			height: 40px;
			width: 40px;
			line-height: 38px;
			font-size:20px;
			display: inline-block;
			transition: .8s;
			text-align: center;
			+a{
				margin-left: 15px;
			}
			&:hover{
				background-color: $color1;
				color:#fff;
				transform: scale(1.1);
			}
		}/*a*/
	}/*social*/
}/*footer*/

.band{
	background-color: $color1;
	padding: 35px 300px;
	p{
		color:#fff;
		font-size:12px;
		font-weight: 300;
		margin: 0;
		display: block;
	}
	.iwt {
		color: #fff;
		font-size: 12px;
		font-weight: 300;
		float: right;
		background: url("../images/iwt.png") no-repeat center left;
		padding: 6px 0 6px 40px;
		position: relative;
		z-index: 5;
	}
}/*band*/