.slider_dv{
	background: url("../images/slider_bg.jpg") no-repeat center center;
	height: 900px;
	position: relative;
	padding: 215px 315px 0;

	.form{
		background-color: rgba(#fff,.7);
		position: relative;
		border-radius: 5px;
		padding: 35px 110px 70px;
		float:left;
		width: 630px;

		.show_btn {
			position: absolute;
			right: 30px;
			top: 20px;
			font-size: 20px;
			color:$color1;
			transition: .5s;
			cursor: pointer;
			&.active{
				transform: rotate(180deg);
			}
		}/*show_btn*/


		.title{
			color:$color1;
			font-weight: 700;
			font-size:24px;
			line-height: 1.1;
			margin-bottom: 55px;
			strong{
				display: block;
				font-weight: 900;
				font-size:30px;
			}/*storng*/
		}/*title*/

		.select2-container .select2-selection--single {
			height: 50px;
		}
		.select2-container--default .select2-selection--single {
			background-color: #fff;
			border: 0;
			border-radius: 5px;
		}
		.select2-container .select2-selection--single .select2-selection__rendered {
			padding: 11px 50px 11px 15px;
		}
		.select2-container--default .select2-selection--single .select2-selection__arrow b {
			display: none;
		}
		.select2-container--default .select2-selection--single .select2-selection__arrow {
			height: auto;
			position: absolute;
			top: 50%;
			right: 20px;
			width: auto;
			transform: translateY(-50%);
			&:before{
				content: "\f107 ";
				color: $color1;
				font-family: fontawesome;
				position: absolute;
				top:50%;
				transform: translateY(-50%);
				right: 0;
				font-size:22px;
			}
		}
		.select2-container--default .select2-selection--single .select2-selection__placeholder {
			color: #222;
		}

		.cst_input{
			position: relative;
			height: 50px;
			margin: 30px 0;
			&:before{
				content: "\f073";
				color: $color1;
				font-family: fontawesome;
				position: absolute;
				top:50%;
				transform: translateY(-50%);
				right: 20px;
				font-size:16px;
			}
			input{
				width: 100%;
				background-color: #fff;
				border-radius: 5px;
				border: 0;
				height: 100%;
				padding-left: 18px;
				font-size: 16px;
				color: #222;
				font-weight: 300;
				padding-right: 50px;
				&::placeholder{
					opacity: 1;
					color: #222;
				}
			}/*input*/
		}/*cst_input*/

		.button{
			padding: 13px 0;
			width: 100%;
			color:#fff;
			font-size:16px;
			font-weight: 900;
			text-align: center;
			background-color: $color2;
			transition: .5s ease-in-out;
			border: 0;
			border-radius: 5px;
			margin-top: 30px;
			&:hover{
				background-color: $color1;
			}
		}
		input{
			&::placeholder{
				opacity: 1!important;
				color:#222!important;
			}
		}
	}/*form*/

	.right{
		float:left;
		width: calc(100% - 630px);

		.slider-byk{
			.item{
				position: relative;
				overflow: hidden;
				.top{
					position: relative;
					z-index: 2;
					.title{
						text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
						line-height: 1.1;
						float:left;
						padding-left: 105px;
						width: calc(100% - 190px);
						.mrk{
							display: block;
							font-size:59px;
							font-weight: 900;
							color:#fff;
						}
						.mdl{
							display: block;
							font-size:59px;
							font-weight: 700;
							color:#fff;
						}
						.egk{
							font-family: 'ArtelyInksPERSONALUSE';
							color:$color2;
							display: block;
							font-size:33px;
						}
					}/*title*/
					.price{
						float:left;
						width: 190px;
						a{
							display: block;
							position: relative;
							text-align: center;
							border: 3px solid $color2;
							&:before{
								content: "";
								position: absolute;
								left:0;
								right:0;
								margin: 0 auto;
								bottom: -25px;
								width: 0;
								height: 0;
								border-style: solid;
								border-width: 25px 27.5px 0 27.5px;
								border-color: $color2 transparent transparent transparent;
							}
							span{
								display: block;
								&:first-child{
									color:$color2;
									font-size:20px;
									font-weight: 900;
									padding: 18px 0;
								}
								&:last-child{
									background-color: $color2;
									font-size:18px;
									font-weight: 500;
									color:#fff;
									padding: 19px 0;
								}
							}/*span*/
						}/*a*/
					}/*price*/
				}/*top*/
				.imaj{
					position: relative;
					z-index: 1;
					img{}
				}
				.thumbs{
					width:100%;
					text-align: right;
					.item{
						position: relative;
						overflow: hidden;
						border-radius: 5px;
						border: 2px solid $color2;
						width: 100px;
						height: 50px;
						background-color: #fff;
						cursor: pointer;
						display: inline-block;
						margin-left: 24px;
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}/*item*/
				}/*thumbslib/images/*/
			}/*item*/
		}/*slider-byk*/


		.slick-dots {
			bottom: -70px;
			width: 100%;
			left: 0;
			right: 0;
			li {
				background-color: transparent;
				border:1px solid #fff;
				width:12px;
				height:12px;
				margin: 0 5px;
				border-radius: 50%;
			}
			.slick-active {
				background-color: $color2;
			}
		}/*slick-dots*/
	}/*right*/
}/*slider*/

.highlights{
	background: url("../images/onecikan_bg.jpg") no-repeat center center;
	padding: 0 300px;
	height: 840px;
	.t_title{
		display: block;
		margin: 93px 0 33px;
		h2{
			display: block;
			font-size:30px;
			font-weight: 900;
			color:#fff;
			margin-bottom: 10px;
		}
		p{
			font-weight: 300;
			font-size:18px;
			color:#fff;
			display: block;
		}
	}/*t_title*/
	.highlights_slc{
		.item{
			position: relative;
			overflow: hidden;
			height: 540px;
			padding: 30px 28px;

			&:before,
			&:after{
				content: "";
				position: absolute;
				z-index: 1;
			}
			&:before{
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 30px 406px 0 0;
				border-color: #fff transparent transparent transparent;
			top:0;
				left:0;
				right:0;
			}
			&:after{
				border-top: 30px solid $color2;
				background-color: #fff;
				height: 366px;
				width: 470px;
				transform: rotate(-5deg);
				bottom: -30px;
				left: -32px;
				right: 0;
			}

			.title{
				position: relative;
				z-index: 2;
				margin-top: 22px;
				h3{
					font-size:20px;
					font-weight: 700;
					color:#fff;
					display: block;
				}
				p{
					font-size:17px;
					font-weight: 300;
					color:#fff;
					display: block;
				}
			}/*title*/
			.imaj{
				position: relative;
				z-index: 2;
				margin-bottom: 58px;
				img{
					display: block;
					margin: 0 auto;
				}
			}/*imaj*/
			.items{
				position: relative;
				z-index: 2;
				display: table;
				width: 100%;
				.sm_item{
					float:left;
					background-color: #eee;
					border-radius: 5px;
					text-align: center;
					width: 64px;
					height: 64px;
					padding-top: 9px;
					+ .sm_item{
						margin-left: 7.5px;
					}/* + sm_item*/
					img{
						display: block;
						margin: 0 auto;
					}
					span{
						display: block;
						font-weight: 300;
						color:#222;
						font-size:12px;
						line-height: 1;
					}
					strong{
						color:$color1;
						font-weight: 700;
						font-size:15px;
						display: block;
					}
				}/*sm_item*/
			}/*items*/
			a{
				position: relative;
				z-index: 2;
				border: 2px solid $color2;
				color:$color2;
				display: block;
				text-align: center;
				padding: 11px 0;
				border-radius: 5px;
				margin-top: 30px;
				font-size: 16px;
				font-weight: 900;
				transition: .5s ease-in-out;
				&:hover{
					background-color:$color2;
					color:#fff;
				}/*hover*/
			}/*a*/
		}/*item*/

		.slick-slide {
			margin: 0 14px;
		}
		.slick-list {
			margin: 0 -14px;
		}
		.slick-next::before,
		.slick-prev::before{
			font-family: fontawesome;
			color: $color1;
			font-size: 24px;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 30px;
			height: 30px;
			line-height: 30px;
			border-radius: 50%;
			opacity: 1;
			border: 1px solid #eee;
		}
		.slick-next::before{content: "\f105";}
		.slick-prev::before{content: "\f104";}
		.slick-prev {left: -18px;}
		.slick-next {right: -18px;}
		.slick-prev,
		.slick-next {
			height: 30px;
			width: 30px;
			z-index: 9;
			opacity: 1;
			&:hover{
				&:before{
					background-color: $color1;
					color:#fff;
					border: 1px solid transparent;
				}
			}/*hover*/
		}
	}/*highlights_slc*/
}/*highlights*/

.showcase{
	padding: 0 300px;
	.t_title{
		display: block;
		margin: 93px 0 33px;
		h2{
			display: block;
			font-size:30px;
			font-weight: 900;
			color:$color1;
			margin-bottom: 10px;
		}
		p{
			font-weight: 300;
			font-size:18px;
			color:$color1;
			display: block;
		}
	}/*t_title*/
	.item{
		position: relative;
		overflow: hidden;
		height: 580px;
		padding: 20px 15px;
		border-radius: 15px;
		border: 1px solid #ddd;
		margin-bottom: 30px;
 		&:before{
			content: "";
			position: absolute;
			z-index: 1;
			left: -1px;
			right: -1px;
			bottom: 110px;
			background-color: #fff;
			border-radius: 15px;
			box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, 0.1);
			height: 250px;
		}
		.title{
			position: relative;
			z-index: 2;
			h3{
				font-size:20px;
				font-weight: 700;
				color:$color1;
				display: block;
			}
			p{
				font-size:17px;
				font-weight: 300;
				color:#222222;
				display: block;
			}
		}/*title*/
		.imaj{
			position: relative;
			z-index: 2;
			margin-bottom: 35px;
			margin-top: 60px;
			img{
				display: block;
				margin: 0 auto;
			}
		}/*imaj*/
		.items{
			position: relative;
			z-index: 2;
			display: table;
			width: 100%;
			.sm_item{
				float:left;
				background-color: #eee;
				border-radius: 5px;
				text-align: center;
				width: 64px;
				height: 64px;
				padding-top: 9px;
				margin-bottom: 20px;
				margin-right: 4.5%;
				margin-left: 4.5%;
				img{
					display: block;
					margin: 0 auto;
				}
				span{
					display: block;
					font-weight: 300;
					color:#222;
					font-size:12px;
					line-height: 1;
				}
				strong{
					color:$color1;
					font-weight: 700;
					font-size:15px;
					display: block;
				}
			}/*sm_item*/
		}/*items*/
		a{
			position: relative;
			z-index: 2;
			border: 2px solid $color2;
			color:$color2;
			display: table;
			text-align: center;
			padding: 11px 35px;
			border-radius: 5px;
			font-size: 16px;
			font-weight: 900;
			transition: .5s ease-in-out;
			margin: 39px auto 0;
			&:hover{
				background-color:$color2;
				color:#fff;
			}/*hover*/
		}/*a*/
	}/*item*/
}/*showcase*/

.newsletter{
	margin-top: 10px;
	background: url("../images/bulten_bg.jpg") no-repeat center center;
	height:504px;
	h2{
		font-size:30px;
		font-weight: 700;
		color:#fff;
		display: block;
		padding: 85px 0 11px;
	}
	p{
		font-size:16px;
		font-weight: 300;
		color:#fff;
		margin: 0;
	}
	.bltn{
		width: 630px;
		height: 50px;
		position: relative;
		margin: 30px auto 0;
		input{
			font-size:16px;
			font-weight: 300;
			color:#222;
			width: 100%;
			height: 100%;
			border-radius: 5px;
			box-shadow: none;
			padding-left: 20px;
			padding-right: 50px;
			text-align: left;
			border: 0;
			&::placeholder{
				opacity: 1;
				color:#222;
			}
		}/*input*/
		.button{
			border: 0;
			cursor:pointer;
			padding: 0;
			background: none;
			font-size:20px;
			color:$color1;
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			right: 20px;
		}
	}/*bltn*/


}/*newsletter*/

.news{
	transform: translateY(-150px);
	margin-bottom: -150px;
	position: relative;
	z-index: 2;
	.news_slc{
		padding: 0 130px;
		.item{
			position: relative;
			overflow: hidden;
			text-align: center;
			&:hover{
				img{
					box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
					border-radius: 15px;
				}
			}/*hover*/
			img{
				width: 100%;
				object-fit: cover;
				height: 230px;
				transition: .5s;
			}
			.title{
				display: block;
				font-size:16px;
				font-weight: 900;
				color:#222;
				margin: 39px 0 17px;
			}
			.desc{
				display: block;
				font-size:16px;
				font-weight: 300;
				color:#222;
				line-height: 20px;
			}
			.button{
				position: relative;
				z-index: 2;
				border: 2px solid $color2;
				color:$color2;
				display: table;
				text-align: center;
				padding: 11px 35px;
				border-radius: 5px;
				font-size: 16px;
				font-weight: 900;
				transition: .5s ease-in-out;
				margin: 33px auto 0;
				&:hover{
					background-color:$color2;
					color:#fff;
				}/*hover*/
			}/*a*/
		}/*item*/
		.slick-slide {
			margin: 0 14px;
		}
		.slick-list {
			margin: 0 -14px;
		}
		.slick-next::before,
		.slick-prev::before{
				 font-family: fontawesome;
				 color: $color1;
				 font-size: 24px;
				 background-color: #fff;
				 position: absolute;
				 top: 50%;
				 left: 50%;
				 transform: translate(-50%, -50%);
				 width: 30px;
				 height: 30px;
				 line-height: 30px;
				 border-radius: 50%;
				 opacity: 1;
				 border: 1px solid #eee;
			 }
		.slick-next::before{content: "\f105";}
		.slick-prev::before{content: "\f104";}
		.slick-prev {left: 111px;}
		.slick-next {right: 111px;}
		.slick-prev,
		.slick-next {
			height: 30px;
			width: 30px;
			z-index: 9;
			opacity: 1;
			top: 29.5%;
			&:hover{
				&:before{
					background-color: $color1;
					color:#fff;
					border: 1px solid transparent;
				}
			}/*hover*/
		}
	}/*news_slc*/


}/*news*/

.brands{
	padding: 0 300px;

	.t_title{
		display: block;
		margin: 93px 0 33px;
		h2{
			display: block;
			font-size:30px;
			font-weight: 900;
			color:$color1;
			margin-bottom: 10px;
		}
		p{
			font-weight: 300;
			font-size:18px;
			color:$color1;
			display: block;
		}
	}/*t_title*/

	.brands_slc{
		.item{
			position: relative;
			overflow: hidden;
			background-color: #fff;
			border-radius: 3px;
			border: 1px solid #efefef;
			height: 75px;
			img{
				position: absolute;
				left: 50%;
				top:50%;
				transform: translate(-50%,-50%);
				max-width: 90%;
				max-height: 65px;
			}
		}
		.slick-slide {
			margin: 0 14px;
		}
		.slick-list {
			margin: 0 -14px;
		}
		.slick-next::before,
		.slick-prev::before{
			font-family: fontawesome;
			color: $color1;
			font-size: 24px;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 30px;
			height: 30px;
			line-height: 30px;
			border-radius: 50%;
			opacity: 1;
			border: 1px solid #eee;
		}
		.slick-next::before{content: "\f105";}
		.slick-prev::before{content: "\f104";}
		.slick-prev {left: -18px;}
		.slick-next {right: -18px;}
		.slick-prev,
		.slick-next {
			height: 30px;
			width: 30px;
			z-index: 9;
			opacity: 1;
			&:hover{
				&:before{
					background-color: $color1;
					color:#fff;
					border: 1px solid transparent;
				}
			}/*hover*/
		}
	}/*brands_slc*/
}/*brands*/






