html,body {overflow-x:hidden} 
h1,h2,h3,h4,h5,h6,ul{margin:0;padding:0;}
img{border:0;}
a,a:hover,a:focus{text-decoration: none;}
*,*:focus{outline: none !important;} 
body{margin:0;padding:0;font-family: 'Montserrat', sans-serif;background-color: #f4f4f4}

@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap&subset=latin-ext');

$color1:#009db1;
$color2:#ed6d05;

.color1{
	color:#009db1!important;
}
.color2{
	color:#ed6d05!important;
}

@font-face {
	font-family: 'ArtelyInksPERSONALUSE';
	src:url('../fonts/ArtelyInksPERSONALUSE.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ArtelyInksPERSONALUSE.woff') format('woff'),
		url('../fonts/ArtelyInksPERSONALUSE.ttf')  format('truetype'),
		url('../fonts/ArtelyInksPERSONALUSE.svg#ArtelyInksPERSONALUSE') format('svg');
	font-weight: normal;
	font-style: normal;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{
	background-color: $color2;
}
.custom-control-input:focus ~ .custom-control-label::before,
.custom-checkbox:focus ~ .custom-control-label::before {
	-webkit-box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba($color2,.25);
	box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba($color2,.25);
}
.custom-control-input:checked ~ .custom-control-label::before {
	color:#fff;
	border-color:$color2;
	background-color: $color2;
}